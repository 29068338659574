export enum LightModeColor {
  Neutral1 = "#F4F6F9",
  Neutral2 = "#EDEEF1",
  Neutral3 = "#D5D8DD",
  Neutral4 = "#A8ACB2",
  Neutral5 = "#8B8E95",
  Neutral6 = "#6C6E73",
  Neutral7 = "#585A5F",
  Neutral8 = "#4A4C50",
  Neutral9 = "#323437",
  Neutral10 = "#1B1C1E",

  Blue1 = "#E2EDFF",
  Blue2 = "#D4E3FF",
  Blue3 = "#BDD4FF",
  Blue4 = "#81B1FF",
  Blue5 = "#458CFF",
  Blue6 = "#2578FF",
  Blue7 = "#0E64EE",
  Blue8 = "#074AB7",
  Blue9 = "#00368D",
  Blue10 = "#002769",

  Red1 = "#FFF0F3",
  Red2 = "#FFCED8",
  Red3 = "#FEAEBE",
  Red4 = "#FD889F",
  Red5 = "#FF5C7E",
  Red6 = "#FA315A",
  Red7 = "#EC1843",
  Red8 = "#CB072F",
  Red9 = "#AB0427",
  Red10 = "#85001B",

  Yellow1 = "#FFF0D7",
  Yellow2 = "#FFE7BD",
  Yellow3 = "#FFDDA0",
  Yellow4 = "#FFD281",
  Yellow5 = "#FFC864",
  Yellow6 = "#FFBA3F",
  Yellow7 = "#FFAD1C",
  Yellow8 = "#F19A00",
  Yellow9 = "#CD8300",
  Yellow10 = "#A56900",

  Orange1 = "#FFF1E0",
  Orange2 = "#FFE0B9",
  Orange3 = "#FFC07C",
  Orange4 = "#FFA346",
  Orange5 = "#F58E26",
  Orange6 = "#E8780B",
  Orange7 = "#DA6B00",
  Orange8 = "#BE5C00",
  Orange9 = "#9A4900",
  Orange10 = "#803B00",

  Green1 = "#E1F5EB",
  Green2 = "#C9EDDA",
  Green3 = "#9EE2BE",
  Green4 = "#70D29E",
  Green5 = "#3FC87F",
  Green6 = "#1BB563",
  Green7 = "#05A14E",
  Green8 = "#068441",
  Green9 = "#086D37",
  Green10 = "#08532B",

  Purple1 = "#F6EFFF",
  Purple2 = "#E4D0FF",
  Purple3 = "#CCAAFB",
  Purple4 = "#B180F5",
  Purple5 = "#9350F1",
  Purple6 = "#7B2AEC",
  Purple7 = "#670EE4",
  Purple8 = "#5813BB",
  Purple9 = "#4705A4",
  Purple10 = "#34007E",

  // this wasn't named, but did appear here: https://www.figma.com/file/fpzpXxJAJ8Ld1oiJvd5WSU/Phone-System-Settings?node-id=796%3A15941&t=GT0Uu2gEsxUaH6Oo-4
  MessageBackgroundGreen = Green1,
  MessageForegroundGreen = "#009332",
  MessageBackgroundRed = Red1,

  // the following are meant to sync colors by usage across form components
  FormControlDisabledColor = Neutral8,
  FormControlDisabledBorderColor = Neutral4,

  RedactedInternalMessageBackgroundColor = "#FFF7EB",

  White = "#FFFFFF",
  Black = "#000000",
  TransparentBlack = "rgba(0,0,0,.2)",
  TransparentWhite = "rgba(255, 255, 255, 0.05)",
  Transparent = "transparent",
}

export type HexColor = `#${string}`;
export type ColorType = Record<keyof typeof LightModeColor, HexColor>;

export enum DarkModeColor {
  Neutral1 = "#060709",
  Neutral2 = "#2F3032",
  Neutral3 = "#3F4144",
  Neutral4 = "#606265",
  Neutral5 = "#808287",
  Neutral6 = "#96999E",
  Neutral7 = "#B0B3B9",
  Neutral8 = "#C5C8CE",
  Neutral9 = "#D7D9DE",
  Neutral10 = "#E4E5EA",

  Blue1 = "#202F47",
  Blue2 = "#2C456F",
  Blue3 = "#33568F",
  Blue4 = "#3360A9",
  Blue5 = "#4774C0",
  Blue6 = "#5585D6",
  Blue7 = "#6D9BE7",
  Blue8 = "#83AAEB",
  Blue9 = "#A6C1ED",
  Blue10 = "#CCD9EF",

  Red1 = "#3F2A2E",
  Red2 = "#63303B",
  Red3 = "#892B3E",
  Red4 = "#B0213E",
  Red5 = "#CD1137",
  Red6 = "#EC0736",
  Red7 = "#F92D56",
  Red8 = "#FA5D7F",
  Red9 = "#FC849D",
  Red10 = "#FCB8C6",

  Yellow1 = "#423D33",
  Yellow2 = "#5B523E",
  Yellow3 = "#897B61",
  Yellow4 = "#AA9367",
  Yellow5 = "#CBAA6B",
  Yellow6 = "#E8BD6D",
  Yellow7 = "#F9CC77",
  Yellow8 = "#FFD78A",
  Yellow9 = "#FEE3B0",
  Yellow10 = "#FFECCB",

  Orange1 = "#49352C",
  Orange2 = "#5A4336",
  Orange3 = "#805B47",
  Orange4 = "#986749",
  Orange5 = "#B67445",
  Orange6 = "#D88543",
  Orange7 = "#F2964D",
  Orange8 = "#FCAA69",
  Orange9 = "#FFC394",
  Orange10 = "#FFD9BB",

  Green1 = "#263D30",
  Green2 = "#234B31",
  Green3 = "#1A5C31",
  Green4 = "#187036",
  Green5 = "#288749",
  Green6 = "#41AA66",
  Green7 = "#59BC7D",
  Green8 = "#78CA96",
  Green9 = "#A2DAB6",
  Green10 = "#C5EDD4",

  Purple1 = "#2D2438",
  Purple2 = "#3E3052",
  Purple3 = "#513C70",
  Purple4 = "#604685",
  Purple5 = "#754FAB",
  Purple6 = "#8A59D0",
  Purple7 = "#A471ED",
  Purple8 = "#AF82EF",
  Purple9 = "#C3A2F1",
  Purple10 = "#D8C2F6",

  White = "#FFFFFF",
  Black = "#1B1C1E",

  // One-off colors
  RedactedInternalMessageBackgroundColor = "#2F2D29",
}

export enum Gradient {
  Blue = "linear-gradient(15.03deg, #2D70FF 8.29%, #3B9DFF 92.11%)",
  Gray = "linear-gradient(10.26deg, #80899C 8.25%, #9AA1B0 91.29%)",
  Yellow = "linear-gradient(12.1deg, #DE9E5E 2.28%, #FFBE00 105.51%)",
}

export enum Shadow {
  FloatingModal = "0px 0px 32px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.08)",
  AnchoredModal = "0px 16px 24px rgba(0, 0, 0, 0.08), 0px -4px 12px rgba(0, 0, 0, 0.08)",
  Menu = "0px 8px 16px rgba(0, 0, 0, 0.06), 0px 8px 8px rgba(0, 0, 0, 0.1)",
  PanelRight = "-6px 0px 10px rgba(0, 0, 0, 0.04), -2px 0px 2px rgba(0, 0, 0, 0.02)",
  Banner = "0px -0.6px 0px rgba(0, 0, 0, 0.16), 0px 6px 10px rgba(0, 0, 0, 0.15)",
  MainNavigation = "0.3px 0px 2px rgba(0, 0, 0, 0.15)",
  PanelLeft = "6px 0px 10px rgba(0, 0, 0, 0.24), 2px 0px 2px rgba(0, 0, 0, 0.12)",
  SeparatorTop = "0px -0.6px 0px rgba(0, 0, 0, 0.16)",
  Navbar = "0px 0.5px 0px rgba(0, 0, 0, 0.16)",
  SeparatorBottom = "inset 0px -0.5px 0px rgba(0, 0, 0, 0.12)",
  SeparatorBottomHovered = "inset 0px -0.5px 0px #E6E6EA",
  SeparatorBottomFocused = "inset 0px -0.5px 0px #CBCDD1",
  SegmentedControl = "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.12)",
  PostedNoteInner = "inset 0px 1px 2px rgba(0, 0, 0, 0.06), inset 0px -1px 2px rgba(0, 0, 0, 0.06)",
  Outline = "0px 0px 0px 0.5px rgba(0,0,0,0.16)",
  Popover = "0px 2px 4px rgba(0, 0, 0, 0.12)",
  CallDialog = "0px 1px 10px 0px rgba(0,0,0,0.3), 0px 4px 8px 3px rgba(0,0,0,.15), 0px 0px 10px 0px rgba(0,0,0,0.1)",
}
